import { stringToVariable } from '@/util/common'

//  选择表格 某一项 的弹窗
export default {
    props: {
        selectedPersons: {
            type: Array,
            default () {
                return []
            }
        },
        // 是否是单选
        isOnePerson: {
            type: Boolean,
            default: true
        },
        //是否显示 可以选择 所有人 的按钮
        isShowSelectAll: {
            type: Boolean,
            default: false
        },
        // 可选择全部。 0 不会出现 “所有项” 的选择，  1 会出现 “所有项”
        isAll_f: {
            type: Number,
            default: 0  //
        },
        // 是那个页面进来的。从而进行不一样的操作
        pageType: {
            type: String,
            default: ''
        },
        // getInit() 方法 返回来，res 要取的层级
        resRule: {
            type: String,
            default: 'data'
        },
        isShowSelectText: {
            type: Boolean,
            default: false
        },
        // getInit 传递进来的 额外参数
        rqParmasFather: {
            type: Function,
            default: () => () => { }
        }
    },
    data () {
        return {
            filterData: {
                pageIndex: 1,
                pageSize: 5
            },
            conditionData: [{ normalBtn: 'search', }, { normalBtn: 'reset' }, { slotName: 'isAll' }],
            columns: [
                {
                    label: '操作', input_type: 'button', width: 120, attribute: { fixed: 'right', align: 'center' },
                    list: [
                        { text: '选择', method: 'selectRow' }
                    ]
                }
            ],
            NowPersons: [],
            checked: false, //是否全选 
            isAll: 0, //
            params: {},
            except_person: [], // 在全选的基础上，去掉不需要的人员 的数组
            keyName: 'nickname', // 展示 “已选择” 拼接 取的字段是
            rq_Method: '', // 请求的方法

            IdKeyWord: 'id'
        }
    },
    computed: {
        NowPersons2: function () {
            let str = ''
            this.NowPersons.forEach(item => {
                str = str + item[this.keyName] + '、'
            })
            str = str.substring(0, str.length - 1)
            return str
        },
        except_person_name: function () {
            let str = ''
            this.except_person.forEach(item => {
                str = str + item[this.keyName] + '、'
            })
            str = str.substring(0, str.length - 1)
            return str
        }
    },
    created () {
        console.log('组件重新', this.selectedPersons)
        this.NowPersons = [...this.selectedPersons]
        this.isAll = this.isAll_f
        if (this.isAll == 1) {
            this.checked = true
            let params_f = this.params_f
            let params_f_arr = Object.keys(params_f)
            let conditionData_keyArr = this.conditionData.map(v => v.key)
            params_f_arr.forEach(item => {
                let isExist = conditionData_keyArr.find(v => v == item)
                if (isExist) {
                    this.$set(this.filterData, isExist, params_f[item])
                    this.$nextTick(() => {
                        this.getInit2()
                    })
                }
            })
        } else {
            this.getInit2()
        }
    },
    methods: {
        init2 () {
            this.loading = true
            // 超时隐藏（实际应在接口返回数据时去掉loading）
            setTimeout(() => {
                this.loading = false
            }, 10 * 1000)
            this.getInit2()
        },
        rqParmasNew () {
            let obj = this.rqParmas()
            let rqParmasFather = this.rqParmasFather()
            if (rqParmasFather !== {}) {
                obj = {
                    ...rqParmasFather,
                    ...this.rqParmas()
                }
            }
            return obj
        },
        getInit2 () {
            this.loading = true
            let rq_Data = this.filterData
            this.rq_Method(rq_Data).then(res => {
                // console.log('res==',res)
                this.loading = false
                let data = stringToVariable({ oldObj: res, str: this.resRule })
                // console.log('let--------',data)
                this.tableData = data.data
                this.tableData.map(e => e.spaceStatusName = ['未关联', '已关联'][e.spaceStatus])
                this.total = data.total
                this.dealGetInit()
            })
        },
        dealGetInit () {
            if (this.isShowSelectAll && this.checked) { //如果是全选的，默认选择
                this.tableData.forEach(item => {
                    this.$nextTick(() => {
                        this.$refs.tst.$refs.jaTable.toggleRowSelection(item, true)
                    })

                    this.except_person.forEach(v => {
                        this.$nextTick(() => {
                            if (item[this.IdKeyWord] == v[this.IdKeyWord]) {
                                this.$refs.tst.$refs.jaTable.toggleRowSelection(item, false)
                            }
                        })
                    })
                })
            } else {
                this.NowPersons.forEach(item => {
                    this.tableData.forEach(v => {
                        if (item[this.IdKeyWord] == v[this.IdKeyWord]) {
                            this.$nextTick(() => {
                                this.$refs.tst.$refs.jaTable.toggleRowSelection(v, true)
                            })
                        }
                    })
                })
            }
        },
        // 选择所有人
        selectAllPerson () {
            // 只要点击过“全选”按钮，都要把已经选择的清理掉
            this.NowPersons.splice(0, this.NowPersons.length)
            this.NowPersons2 = ''
            if (this.isShowSelectAll) {
                this.isAll = this.checked ? 0 : 1
                if (this.checked) {//全选，调用全选的样式
                    this.tableData.forEach(v => {
                        this.$refs.tst.$refs.jaTable.toggleRowSelection(v, true)
                    })
                } else {//取消全选，清除样式 、除外的人、除外的人的名单
                    this.claerAll()
                }
            }
        },
        // 清除所有
        claerAll () {
            this.tableData.forEach(v => {
                this.$refs.tst.$refs.jaTable.toggleRowSelection(v, false)
            })
            this.except_person.splice(0, this.except_person.length)
            this.except_person_name = ''
        },
        handleSelectionAll (Selection) {
            // //console.log('点击l全选 ',Selection)
            const len = Selection.length
            const selectedPersons = [...this.NowPersons]
            if (len == this.tableData.length) { // 全部选中
                // 若没有，就加
                Selection.forEach(item => {
                    const isExist = selectedPersons.findIndex(v => v[this.IdKeyWord] == item[this.IdKeyWord])
                    if (isExist == -1) { // 若不存在
                        // //console.log('不存在',this.selectedPersons)
                        this.NowPersons.push(item)
                    }
                })
            }
            if (len == 0) { // 全部反选，有就删掉
                selectedPersons.forEach((item, index) => {
                    const isExist = this.tableData.findIndex(v => v[this.IdKeyWord] == item[this.IdKeyWord])
                    if (isExist > -1) { // 若存在,就得删掉
                        // //console.log('存在-===>',isExist)
                        const del_id = this.tableData[isExist][this.IdKeyWord]
                        const del_index = this.NowPersons.findIndex(i => i[this.IdKeyWord] == del_id)
                        this.NowPersons.splice(del_index, 1)
                    }
                })
            }
        },
        handleSelectItem (selection, row) {
            //console.log('已经勾选的==>', selection, row)
            if (this.isShowSelectAll && this.checked) {//若是 选择所有人，针对的是 额外的名单，样式的显示
                this.checkInList_formAll(row)
            } else {
                this.checkInList(row)
            }
        },
        checkInList_formAll (row) {
            const isExist = this.except_person.findIndex(item => item[this.IdKeyWord] == row[this.IdKeyWord])
            if (isExist > -1) { // 若已经存在，说明要删掉
                const label = this.except_person[isExist][this.keyName]
                this.except_person.splice(isExist, 1)
                this.$refs.tst.$refs.jaTable.toggleRowSelection(row, true)
            } else { // 若没有，就增加
                this.except_person.push(row)
                this.$refs.tst.$refs.jaTable.toggleRowSelection(row, false)
            }
            //console.log('=============*********',this.params)
        },
        checkInList (row) {
            //console.log('点击了选择  某一项===》',row)
            const isExist = this.NowPersons.findIndex(item => item[this.IdKeyWord] == row[this.IdKeyWord])
            if (isExist > -1) { // 若已经存在，说明要删掉
                // const label = this.NowPersons[isExist][this.keyName]
                this.NowPersons.splice(isExist, 1)
                this.$refs.tst.$refs.jaTable.toggleRowSelection(row, false)
            } else { // 若没有，就增加
                this.NowPersons.push(row)
                this.$refs.tst.$refs.jaTable.toggleRowSelection(row, true)
            }
        },
        reset () {
            this.filterData = {
                pageIndex: 1,
                pageSize: 10,
            }
            this.pagination.total = 0
            this.pagination.pageSize = 10
            // 若是全选，清除全选，
            if (this.isShowSelectAll && this.checked) {
                this.checked = false
                this.isAll = 1
                this.claerAll()
            }
            this.init2()
        },
        search () {
            // 若是全选，清除全选，
            if (this.isShowSelectAll && this.checked) {
                this.checked = false
                this.isAll = 1
                this.claerAll()
            }

            this.filterData.pageIndex = 1
            if (this.isShowSelectAll) {
                this.$set(this.params, 'buildingNo', this.filterData.buildingCode)
                this.$set(this.params, 'roomNo', this.filterData.roomCode)
                this.$set(this.params, 'name', this.filterData.name)
                this.$set(this.params, 'orgId', this.filterData.orgId)
                this.$set(this.params, 'deptId', this.filterData.deptId)

                let conditionData_keyArr = this.conditionData.map(v => v.key)
                conditionData_keyArr.forEach(item => {
                    this.$set(this.params, item, this.filterData[item])
                })
            }
            //console.log('点击查询，现在是 params===》',this.params)  
            this.getInit2()
        },
        // 点击提交
        submit () {
            console.log('thisFinite.N-------', this.NowPersons)
            if (this.NowPersons.length == 0) {
                this.$message.warning('请先选择')
                return
            }
            let obj = {
                list: this.NowPersons,
                params: this.params,
                except_row: this.except_person,
                except_rowIds: this.except_person.map(item => item[this.IdKeyWord]),
                except_rowNames: this.except_person_name
            }
            this.$emit('selectRow', obj) // 
        },
        // 选择某一项
        selectRow (row) {
            //console.log('子组件==》', row)
            if (this.isOnePerson) {
                this.NowPersons.splice(0, this.NowPersons.length, row)
                this.submit()
            } else {
                if (this.isShowSelectAll && this.checked) {//若是 选择所有人，针对的是 额外的名单，样式的显示
                    this.checkInList_formAll(row)
                } else {
                    this.checkInList(row)
                }
            }
        },
    }

}